import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-right-wrapper"
  }, [_c('div', {
    staticClass: "right-title"
  }, [_vm._v("投诉建议")]), _c('div', {
    staticClass: "form"
  }, [_c('section', {
    staticClass: "form-box"
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('span', {
    class: {
      active: !_vm.isShow
    },
    on: {
      "click": _vm.handleIsShow
    }
  }, [_vm._v("投诉建议")]), _c('span', {
    class: {
      active: _vm.isShow
    },
    on: {
      "click": _vm.handleHistory
    }
  }, [_vm._v("历史记录")]), _c('div')]), (_vm.isShow === false ? true : false) ? _c('section', {
    staticClass: "fields"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', [_vm._v("建议类别")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.info.Category,
      expression: "info.Category"
    }],
    class: _vm.isCategoryMust ? 'must' : '',
    attrs: {
      "placeholder": "请选择"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.info, "Category", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        _vm.isCategoryMust = false;
      }]
    }
  }, _vm._l(_vm.CategoryList, function (item) {
    return _c('option', {
      key: item.id,
      domProps: {
        "value": item.suggestName
      }
    }, [_vm._v(" " + _vm._s(item.suggestName) + " ")]);
  }), 0), _c('span', {
    staticClass: "error"
  }, [_vm._v("类别必选")])]), _c('div', {
    staticClass: "field"
  }, [_c('label', [_vm._v("内容描述")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.info.text,
      expression: "info.text"
    }],
    class: _vm.isText ? 'must' : '',
    attrs: {
      "rows": "4"
    },
    domProps: {
      "value": _vm.info.text
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.info, "text", $event.target.value);
      }, function ($event) {
        _vm.isText = false;
      }]
    }
  }), _c('span', {
    staticClass: "error"
  }, [_vm._v("内容描述必填")])]), _c('div', {
    staticClass: "btn"
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c('button', {
    staticClass: "ok",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("提交")])])]) : _vm._e(), _vm.isShow ? _c('section', {
    staticClass: "history"
  }, [_vm._l(_vm.data, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "historyList"
    }, [_c('div', {
      staticClass: "history-title"
    }, [_c('div', {
      staticClass: "history-ti"
    }, [_c('span', [_vm._v(_vm._s(_vm._f("speName")(item.suggestStatus)))]), _c('span', [_vm._v(_vm._s(item.createdAt))])]), (item.replyStatus === 0 ? true : false) ? _c('span', {
      staticClass: "orange"
    }, [_vm._v("未回复")]) : _c('span', {
      staticClass: "gray"
    }, [_vm._v("已回复")])]), _c('div', {
      staticClass: "history-content"
    }, [_c('p', [_vm._v(_vm._s(item.suggestContent))]), (item.replyStatus === 0 ? false : true) ? _c('div', {
      staticClass: "stance"
    }) : _vm._e()]), (item.replyStatus === 0 ? false : true) ? _c('div', {
      staticClass: "reply-title"
    }, [_c('div', {
      staticClass: "reply-ti"
    }, [_c('span', [_vm._v("平台回复")]), _c('span', [_vm._v(_vm._s(item.updatedAt))])])]) : _vm._e(), (item.replyStatus === 0 ? false : true) ? _c('div', {
      staticClass: "reply-content"
    }, [_c('p', [_vm._v(_vm._s(item.platformReply))])]) : _vm._e()]);
  }), _vm.data.length == 0 ? _c('div', [_vm._m(0)]) : _vm._e(), _c('div', {
    staticClass: "pagination",
    staticStyle: {
      "width": "901px",
      "justify-content": "flex-end"
    }
  }, [_c('Pager', {
    attrs: {
      "pageInfo": _vm.pageInfo
    },
    on: {
      "getdata": _vm.handleChange
    }
  })], 1)], 2) : _vm._e()])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "no-data",
    staticStyle: {
      "margin": "40px 0"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "src": require("@/assets-v2/images/pic_empty.png"),
      "alt": ""
    }
  }), _c('p', {
    staticStyle: {
      "color": "#999999",
      "line-height": "normal"
    }
  }, [_vm._v("暂无数据")])]);
}];
export { render, staticRenderFns };