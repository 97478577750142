import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { AccountStorage } from "@services/account";
import Pager from '@/components-v2/pager';
var accountStorage = new AccountStorage();
export default {
  components: {
    Pager: Pager
  },
  data: function data() {
    return {
      isCategoryMust: false,
      isText: false,
      pageInfo: {
        total: 0,
        pageSize: 4,
        pageNum: 1
      },
      current: 1,
      // 页码

      isShow: false,
      info: {
        Category: null,
        text: ""
      },
      CategoryList: [],
      userId: "",
      userName: "",
      account: "",
      data: [],
      rangeBefore: 3,
      rangeAfter: 1
    };
  },
  filters: {
    speName: function speName(type) {
      switch (type) {
        case 1:
          return "药品质量";
        case 2:
          return "物流快递";
        case 3:
          return "客服服务";
        case 4:
          return "业务员服务";
        case 5:
          return "订单问题";
        case 6:
          return "其他";
      }
    }
  },
  created: function created() {
    this.userId = accountStorage.getCacheUserInfo().userId;
    this.account = accountStorage.getCacheUserInfo().account;
    this.userName = accountStorage.getCacheUserInfo().userName;
    this.getCategory();
    this.historyList();
  },
  computed: {},
  methods: {
    // 建议类别
    getCategory: function getCategory() {
      var _this = this;
      var url = "/pjyy-deepexi-aggregation/api/v1/complaint/complaintSuggestNameList";
      this.$getHttpClient().get(url).then(function (res) {
        _this.CategoryList = res.data.data;
      });
    },
    // 建议提交
    handleSubmit: function handleSubmit() {
      var _this2 = this;
      if (this.info.Category === null) {
        this.isCategoryMust = true;
        return false;
      }
      if (this.info.text === "") {
        this.isText = true;
        return false;
      }
      var Category = null;
      this.CategoryList.forEach(function (item) {
        if (item.suggestName === _this2.info.Category) {
          Category = item.id;
        }
      });
      var data = {
        customerId: this.account,
        customerName: this.userName,
        suggestStatus: Category,
        suggestContent: this.info.text
      };
      var url = "/pjyy-deepexi-aggregation/api/v1/complaint/save";
      this.$getHttpClient().post(url, data).then(function (res) {
        _this2.$buefy.toast.open({
          message: res.data.message,
          type: "is-success"
        });
        _this2.info = {
          Category: null,
          text: ""
        };
      });
    },
    // 历史记录
    historyList: function historyList() {
      var _this3 = this;
      var pageIndex = this.current;
      var pageSize = this.pageInfo.pageSize;
      var url = "/pjyy-deepexi-aggregation/api/v1/complaint/findByCondition?customerId=".concat(this.account, "&customerName=").concat(this.userName, "&pageIndex=").concat(pageIndex, "&pageSize=").concat(pageSize);
      this.$getHttpClient().get(url).then(function (res) {
        _this3.data = res.data.data.rows || [];
        _this3.pageInfo.total = res.data.data.total;
        _this3.pageInfo.pageNum = _this3.current;
      });
    },
    handleChange: function handleChange(val) {
      this.current = val;
      this.historyList();
    },
    handleHistory: function handleHistory() {
      this.isShow = true;
      this.historyList();
    },
    handleIsShow: function handleIsShow() {
      this.isShow = false;
    },
    handleCancel: function handleCancel() {}
  },
  updated: function updated() {},
  mounted: function mounted() {}
};